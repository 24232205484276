<template>
    <div v-if="paymentResume" class=" anime transform top-0 left-0 w-full h-full fixed "  :class="paymentResume ? 'translate-x-0' : '-translate-x-full'">
        <div class="container mx-auto bg-white rounded w-10/12 sm:w-8/12 lg:w-6/12 xl:w-4/12 mt-20">
            <div class="py-5 rounded-t flex justify-between p-6">
                <slot name="header"></slot>
                <span class="flex justify-end"><Iconclose @click="passPaymentResume"/></span>
            </div>
            <slot name="body" >

            </slot>
        </div>
    </div>
</template>

<script>
//w-11/12 sm:w-8/12  md:w-10/12 lg:w-7/12 xl:w-5/12
import Iconclose from '../../assets/svg/closeModal.svg?inline';
export default {
   
    props: {
        paymentResume:{
            type: Boolean,
            default: false
        },
    },
    components: {
        Iconclose
    },
    data() {
        return {
           
        }
    },
    
    methods: {
       
        passPaymentResume(){
            this.paymentResumeData = false;
            this.$emit('listenPaymentResume',this.paymentResumeData);
        },
        
    },
    watch: {
    paymentResume: {
      immediate: true,
      handler(paymentResume) {
       if (process.client) {
          if (paymentResume) document.body.style.setProperty("overflow", "hidden");
          else document.body.style.removeProperty("overflow");
        }
      }
    }
    }
}
</script>

<style scoped>
.anime{
    animation: modal 400ms ease-out forwards;
}
@keyframes modal {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }
    25% {
        background-color: rgba(0, 0, 0, 0.15);
    }
    50% {
        background-color: rgba(0, 0, 0, 0.3);
    }
    75% {
        background-color: rgba(0, 0, 0, 0.5);
    }
    100% {
        background-color: rgba(0, 0, 0, 0.75);
    }
}
</style>